a {
  color: $color-brand;
}

a:active,
a:hover,
a:focus {
  color: $color-lightest;
}

p {
}

strong,
b {
  font-weight: 700;
}

em,
i {
  font-style: italic;
}
