html {
  font-size: 16px;
}

body {
  color: $color-darkest;
  font-family: $font-primary;
  font-size: 16px;
  background-color: $color-background;
}

html,
body {
  overflow-x: hidden;

  &.disable-scroll {
    overflow: hidden;
    max-width: 100%;
    max-height: 100%;
  }
}
