.Footer {
  margin-top: 0rem;

  @include break(lg) {
    margin-top: 6rem;
  }
}

.Footer-social {
  display: flex;
  justify-content: center;
}

.Footer-link {
  margin-right: 2rem;
  width: 2.5rem;
  height: 2.5rem;
  background-size: cover;
  background-repeat: no-repeat;

  &--twitter {
    background-image: url("/img/icons/twitter.png");
  }
  &--instagram {
    background-image: url("/img/icons/instagram.png");
  }
  &--reddit {
    background-image: url("/img/icons/reddit.png");
  }

  &:last-child {
    margin-right: 0;
  }
}

.Footer-love {
  padding: gutter(sm) 0;
  margin-top: 3rem;
  text-align: center;
  font-size: 0.8rem;
  color: $color-greyscale-2;

  @include break(md) {
    padding: gutter(lg) 0 0;
  }

  a {
    color: $color-greyscale-2;
  }
}
