.Main {
  margin: 0 auto;

  @include break(md) {
    max-width: 25rem;
    padding: gutter(lg) 0 gutter(md);
  }

  @include break(lg) {
    max-width: 28rem;
  }

  @include break(xl) {
    max-width: 32rem;
    padding: gutter(md)*3 0 gutter(lg);
  }
}

.Main-title {
  font-size: 2.4rem;
  letter-spacing: -0.7px;
  text-align: center;
  color: $color-lightest;
  font-weight: 300;
}

.Main-excerpt {
  opacity: 0.65;
  color: $color-lightest;
  font-size: 1.25rem;
  letter-spacing: -0.7px;
  text-align: center;
  font-weight: 200;
}

.Main-medium-link {
  display: none;
  @include break(md) {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 3rem;
    color: $color-lightest;
    font-weight: 300;
    font-size: 1.25rem;
    letter-spacing: -0.8px;
    text-align: center;
    text-decoration: none;
  }
}

.Main-medium-logo {
  width: 3rem;
  height: 3rem;
  margin-top: 1rem;
  background-image: url("/img/icons/medium.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.Main-medium-iframe {
  @include break(md) {
    display: none;
  }
}
