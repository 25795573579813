.Form {
  display: block;
  margin: 0 auto;
  max-width: 30rem;
}

.Form-group {
  display: flex;
  position: relative;
  margin-bottom: gutter(sm);
  border: 1px solid $color-lightest;
  border-radius: 5px;
}

.Form-input {
  position: relative;
  z-index: 2;
  display: block;
  padding: (gutter(sm)/2) (gutter(sm)/2);
  width: 100%;
  color: $color-lightest;
  background: none;
  font-size: 0.8rem;
  font-weight: 200;
  border-radius: 0;
  box-shadow: none;
  appearance: none;
  transition: border 300ms;

  &::placeholder {
    color: $color-lightest;
    opacity: 0.45;
    font-weight: 300;
    letter-spacing: -0.7px;
  }

  @include break(md) {
    font-size: 1rem;
    font-weight: 300;
  }
}

.Form-label {
  position: absolute;
  top: 50%;
  z-index: 3;
  left: (gutter(sm)/2);
  color: $color-brand-dark-1;
  opacity: 0;
  font-size: 1rem;
  font-weight: 400;
  pointer-events: none;
  transform: translateY(-50%);
  transition: top 300ms, color 300ms, font-size 300ms, opacity 300ms;

  &.is-floating {
    top: -1rem;
    font-size: 0.8rem;
    opacity: 1;
  }
}

.Form-input:focus + .Form-label.is-floating {
  color: $color-lightest;
}

.Form-title {
  color: $color-secondary;
  font-weight: 200;
  letter-spacing: -0.7px;
  text-align: center;
}

.Form-message {
  font-weight: 200;
  letter-spacing: -0.7px;
  text-align: center;

  &--hidden {
    display: none;
  }

  &--success {
    color: $color-secondary;
  }

  &--warning {
    color: $color-warning;
  }
}


