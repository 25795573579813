$file-logo: "/img/logos/logo.svg";
$bg-img: "/img/bg-02@2x.png";
$logo-size: 3rem;

$gutters: (
  sm: 2rem,
  md: 4rem,
  lg: 6rem
);

$font-sans-safe: Lucida Grande, sans-serif;
$font-primary: Montserrat, $font-sans-safe;

$breakpoints: (
  xs: 320px,
  sm: 480px,
  md: 760px,
  lg: 1020px,
  xl: 1280px
);
