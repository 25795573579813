.Container {
  max-width: 48rem;
  margin: 0 auto;
  padding: 0 gutter(sm);

  @include break(xl) {
    max-width: 54rem;
  }

  &--wide {
    max-width: 64rem;
  }

  &--spread {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &--full {
    max-width: 100%;
  }

  &--spaced {
    @include break(md) {
      margin: (gutter(sm) * 1.5) auto;
    }
  }

  &--centered {
    text-align: center;
  }
}
