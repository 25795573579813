.Toggle {
  position: relative;
  margin: 0;
  padding: 0;
  background: none;
  border: none;
  border-radius: 0;
  box-shadow: none;
  appearance: none;
  cursor: pointer;
}

.Toggle::after {
  position: absolute;
  bottom: -0.2rem;
  left: 0;;
  display: block;
  width: 100%;
  height: 0.2rem;
  background-color: #fff;
  border-radius: 0.1rem;
  content: "";
}

.Toggle:focus {
  outline: none;
}

.Toggle--white {
  color: #fff;
}
