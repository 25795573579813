.Logo {
  display: block;
  padding-left: $logo-size + .5rem;
  min-width: $logo-size*5;
  min-height: $logo-size;
  background-repeat: no-repeat;
  background-position: 0 center;
  background-image: url($file-logo);
  text-decoration: none;

  &:hover {
    color: $color-logo-text;
  }
}
