input,
textarea,
button,
select {
  background: none;
  color: inherit;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  line-height: normal;
  border: 0;
  border-radius: 0;
  box-shadow: none;
  appearance: none;
}

input[type="radio"] {
  appearance: radio;
}

input[type="checkbox"] {
  appearance: checkbox;
}

button {
  cursor: pointer;
}
