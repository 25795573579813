.Button {
  display: inline-block;
  margin: 0.125rem;
  padding: 0.8rem 1rem;
  border-radius: .2rem;
  cursor: pointer;
  border: 1px solid $color-lightest;
  color: $color-brand;
  font-size: 0.8rem;
  font-weight: 300;
  user-select: none;
  z-index: 9;

  @include break(md) {
    padding: 0.8rem 1.7rem;
    font-size: 1rem;
  }

  &:hover {
    background: $color-action;
  }

  &:active {
    background: darken($color-action, 10%);
  }

  &--block {
    display: block;
    width: 100%;
  }

  &--primary {
    background: $color-lightest;
    color: $color-brand;

    &:hover {
      background: darken($color-action, 5%);
      border: 1px solid darken($color-action, 5%);
      color: $color-action-text;
    }

    &:active {
      background: darken($color-action, 10%);
      border: 1px solid darken($color-action, 10%);
      color: darken($color-action-text, 10%);
    }
  }
}
