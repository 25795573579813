.Header {
  padding: gutter(sm)/2 0;

  @include break(md) {
    padding: gutter(sm) 0;
  }

  &-container {
    position: relative;
    margin: 0 auto;
    max-width: breakpoint(xl);
  }
}

.Header-toggle {
  @include break(md) {
    display: none;
  }
}
