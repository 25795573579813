.Menu {
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
  display: block;
  visiblity: hidden;
  padding: gutter(sm) gutter(sm);
  width: 100vw;
  height: 100vh;
  background-color: $color-brand-dark-2;
  opacity: 0;
  transition: opacity 300ms, transform 300ms cubic-bezier(0.86, 0, 0.07, 1);
  transform: translate3d(-100%, 0, 0);

  &.is-active {
    z-index: 1000;
    visiblity: visible;
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }

  @include break(md) {
    position: relative;
    top: auto;
    left: auto;
    z-index: 1000;
    visiblity: visible;
    padding: 0;
    width: auto;
    height: auto;
    background: none;
    opacity: 1;
    transform: none;
    transition: none;
  }
}

.Menu-items {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;

  @include break(md) {
    flex-direction: row;
    width: auto;
    height: auto;
  }
}

.Menu-item {
  margin: 0;
  padding: 0;
  font-size: 1.4rem;

  @include break(md) {
    font-size: 1rem;
  }
}

.Menu-link {
  position: relative;
  display: block;
  margin: (gutter(sm)/4) (gutter(sm)/2);
  padding: (gutter(sm)/4) 0;
  color: $color-lightest;
  text-decoration: none;

  &:hover {
    color: lighten($color-menu, 40%);
  }

  &::after {
    position: absolute;
    bottom: 0;
    display: none;
    width: 100%;
    height: 0.2rem;
    background-color: $color-lightest;
    border-radius: 0.2rem;
    content: "";
  }

  @include break(md) {
    color: $color-menu;

    &:hover {
      color: darken($color-menu, 20%);
    }

    &::after {
      background-color: $color-menu;
    }
  }
}

.Menu-item.is-active .Menu-link::after {
  display: block;
}

.Menu-close {
  position: absolute;
  top: 2rem;
  right: 2rem;
  display: inline-block;

  @include break(md) {
    display: none;
  }
}
