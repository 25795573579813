.CubeContainer {
  position: relative;
}

.CubeWrapper {
  display: none;

  @include break(md) {
    display: block;
    position: absolute;
    top: -10rem;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1;
  }
}
