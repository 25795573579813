// Brand
$color-brand: #2a1370;
$color-brand-dark-1: darken($color-brand, 20%);
$color-brand-dark-2: darken($color-brand, 40%);
$color-brand-light-1: lighten($color-brand, 20%);
$color-brand-light-2: lighten($color-brand, 75%);

$color-secondary: #00f0ff;
$color-secondary-dark-1: darken($color-secondary, 20%);
$color-secondary-dark-2: darken($color-secondary, 40%);
$color-secondary-light-1: lighten($color-secondary, 20%);
$color-secondary-light-2: lighten($color-secondary, 75%);

// Greyscale  -- From Darker to Lighter
$color-greyscale-0: #3F4044;
$color-greyscale-1: #6F6064;
$color-greyscale-2: #979797;
$color-greyscale-3: #b6b6b6;
$color-greyscale-4: #e8e9ec;
$color-greyscale-5: #f2f2f2;

// Colours
$color-darkest: #222;
$color-lightest: #FFF;

// Text Colors
$color-text: $color-darkest;
$color-text-secondary: $color-greyscale-2;
$color-text-tertiary: lighten($color-greyscale-1, 25%);
$color-text-light: $color-greyscale-3;

// UI colors
$color-warning: #E65963; // Red
$color-success: #1D993B; // Green

// External colours
$color-twitter: #55ACEE;
$color-facebook: #3B5998;
$color-tumblr: #34465D;

// Layout colors
$color-top--1: $color-brand;
$color-top--2: lighten($color-brand, 20%);
$color-text-top: $color-lightest;
$color-background: $color-lightest;
$color-background--2: $color-greyscale-5;
$color-menu: $color-brand-dark-2;

$color-border: $color-greyscale-4;
$color-action: $color-secondary;
$color-action-text: $color-lightest;
$color-logo-text: $color-lightest;
